<template>
  <div class="home">
    <div class="fd-banner-wrap">
      <swiper class="swiper-no-swiping" :options="swiperOption" >
        <swiper-slide v-for="(item, index) in imgList" :key="index">
          <img style="width:100%; max-height: 398px" :src="item" alt="">
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <div class="fd-home-content" v-if="isshowNormal">
      <!-- 傲度动态 -->
      <div class="fd-home-addt-wrap">
        <div class="fd-title-wrap">
          <span class='span1'>傲度动态</span>
          <span class="span2" @click="ckMoreData(0)">查看更多></span>
        </div>
        <div style="height: 828px">
          <div class="fd-left-item" v-for="(item, index) in addtArr" :key="index" @click="showDetail(item.id, 0)">
            <div style="width: 562px;height:210px">
              <img style="width:100%;height:100%" :src="item.thumbnail" alt="">
            </div>
            <div class="fd-home-left-item-title" v-text="item.title" :title="item.title"></div>
            <div class="fd-home-left-item-time" v-text="getDateData(item.issuerDate)"></div>
            <div class="fd-home-left-item-text" v-text="item.subContent" :title="item.subContent"></div>
          </div>
        </div>
      </div>
        <div style="height:879px">
          <!-- 傲度分享 -->
          <div class="fd-home-adfx-wrap">
            <div class="fd-title-wrap">
              <span class="span1">傲度分享</span>
              <span class="span2" @click="ckMoreData(1)">查看更多></span>
            </div>
            <div>
              <div class="fd-right-item" v-for="(item, index) in adfxArr" :key="index" @click="showDetail(item.id, 1)">
                <div class="fd-right-top-wrap">
                  <div class="fd-right-item-title" v-text="item.title">如何避免投资中</div>
                  <div class="fd-right-item-time" v-text="getDateData(item.issuerDate)">2019.05.24</div>
                </div>
                <div class="fd-right-item-text" v-text="item.subContent" :title="item.subContent"></div>
              </div>
            </div>
          </div>
          <!-- 傲度小知识 -->
          <div class="fd-home-adfx-wrap fd-adxzs-wrap">
            <div class="fd-title-wrap">
              <span class="span1">傲度小百科</span>
              <span class="span2" @click="ckMoreData(2)">查看更多></span>
            </div>
            <div>
              <div class="fd-right-item" v-for="(item, index) in adxzsArr" :key="index" @click="showDetail(item.id, 1)">
                <div class="fd-right-top-wrap">
                  <div class="fd-right-item-title" v-text="item.title">如何避免投资中</div>
                  <div class="fd-right-item-time" v-text="getDateData(item.issuerDate)">2019.05.24</div>
                </div>
                <div class="fd-right-item-text" v-text="item.subContent" :title="item.subContent"></div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div class="fd-home-content" v-else>
      <div class="fd-xiangxi-left-wrap">
        <div class="fd-xiangxi-left-item1" :class="{'fd-checked': keyIndex === 0}" @click="adsyClick(0)">傲度动态</div>
        <div class="fd-xiangxi-left-item1" :class="{'fd-checked': keyIndex === 1}" @click="adsyClick(1)">傲度分享</div>
        <div class="fd-xiangxi-left-item1" :class="{'fd-checked': keyIndex === 2}" @click="adsyClick(2)">傲度小百科</div>
        <div class="fd-xiangxi-left-item2"></div>
      </div>
      <div class="fd-xiangxi-right-wrap" v-if="!ishowDetail">
        <div class="fd-list-wrap">
          <div class="fd-list-item" v-for="(item, index) in listArr" :key="index">
            <div class="fd-list-item-title" @click="showDetail(item.id)" v-text="item.title">如何解读地方政府专项债发行新政及其潜在影响</div>
            <div class="fd-list-item-time" v-text="getDateData(item.issuerDate)">2019.06.30</div>
          </div>
        </div>
        <div class="fd-pagination">
          <div class="fd-button-style"  @click="changePage(1)">首页</div>
          <Page :total="count" :page-size="pageSize" :current="currentPage" @on-change="changePage"/>
          <div class='fd-button-style' @click="changePage(Math.ceil(count/pageSize))">尾页</div>
        </div>
      </div>
      <div class="fd-xiangxi-right-wrap" v-else>
        <div class="fd-detail-title-text" v-text="detailObj.title"></div>
        <div class="fd-detail-time-text" v-text="detailObj.issuerDate"></div>
          <div class="fd-detail-content" v-html="detailObj.contentBody">
          </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import url from '../api/api.js'
import {fetchGet} from '../api/fetch.js'
export default {
  name: "home",
  data () {
    return {
      adxzsArr: [],
      detailObj: {},
      ishowDetail: false,
      pageSize: 12,
      count: 100,
      currentPage: 1, //表示当前页
      listArr: [], // 表示查看更多列表数据
      keyIndex: 0,
      isshowNormal: true,
      adfxArr: [],
      addtArr: [],
      imgList: [require('@/assets/images/banner.png')],
      swiperOption: {
        autoplay: {
          disableOnInteraction: false
        }, //l轮播间隔时间
        // loop: true,　　//是否自动轮播
        Observer: {
          observerParents: true,
          observer: true
        },
        pagination : {
          el: '.swiper-pagination',
          clickable: true,
          clickableClass : 'my-pagination-clickable',
        }, //轮播图中下标点显示
        // paginationClickable :true　　 //轮播图中下标点显示
      }
    }
  },
  props: {
    isClick: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isClick: {
      handler (n, o) {

      }
    },
    deep: true
  },
  methods: {
    // 截取事件
    getDateData (str) {
      return str.split(' ')[0]
    },
    // 展示详细数据
    showDetail (id, index) {
      if (index) {
        this.keyIndex = index
      }
      this.detailObj = {}
      this.isshowNormal = false
      this.ishowDetail = true
      fetchGet(url.getDetail + id).then(res => {
        if (res.data.status == 0) {
          // 请求成功
          this.detailObj = res.data.data
        } else {
          // 请求失败
          console.log(res.data.message)
        }
      })
    },
    // 请求首页傲度动态数据
    getSimpleData (code, currentPage, pageSize) {
      fetchGet(url.getList + code, {messageType: '1,2,3,4,5', currentPage, pageSize}).then(res => {
        if (res.data.status == 0) {
          // 请求成功
          if (res.data.data && Object.keys(res.data.data).length > 0) {
            // 存在数据
            if (code == 'portal-addt') {
              // 傲度动态的数据
              this.addtArr = res.data.data.comContentList.list || []
            } else if(code == 'portal-adfx') {
              this.adfxArr = res.data.data.comContentList.list || []
            } else if (code == 'protal-baike') {
              this.adxzsArr = res.data.data.comContentList.list || []
            }
          } else {
            // 暂无数据
          }
        } else {
          console.log(res.data.message)
        }
      })
    },
    // 改变页数
    changePage (val) {
      let _this = this
      _this.currentPage = val
      if (this.keyIndex === 0) {
        // 傲度动态点击数据
        this.getListData('portal-addt', val, this.pageSize)
      } else {
        // 傲度分享动态数据
        this.getListData('portal-adfx', val, this.pageSize)
      }
      // this.getDetail(_this.articleType, val, 10)
    },
    // 傲度动态、傲度分享点击事件
    adsyClick (index) {
      this.ishowDetail = false
      this.keyIndex = index
      this.listArr = []
      if (this.keyIndex === 0) {
        // 傲度动态点击数据
        this.getListData('portal-addt', 1, this.pageSize)
      } else if (this.keyIndex === 1) {
        // 傲度分享动态数据
        this.getListData('portal-adfx', 1, this.pageSize)
      } else if (this.keyIndex === 2) {
        // 傲度小知识动态数据
        this.getListData('protal-baike', 1, this.pageSize)
      }
    },
    // 请求列表数据
    getListData (code, currentPage, pageSize) {
      fetchGet(url.getList + code, {messageType: '1,2,3,4,5', currentPage, pageSize}).then(res => {
        if (res.data.status == 0) {
          // 请求成功
          if (res.data.data && Object.keys(res.data.data).length > 0) {
            this.listArr = res.data.data.comContentList.list
            this.count = res.data.data.comContentList.totalCount
            this.currentPage = currentPage
          } else {
            // 暂无数据
          }
        } else {
          console.log(res.data.message)
        }
      })
    },
    // 查看更多数据
    ckMoreData (index) {
      if (index === 0) {
        if (!(this.addtArr && this.addtArr.length > 0)) {
          return false
        }
      }
      if (index === 1) {
        if (!(this.adfxArr && this.adfxArr.length > 0)) {
          return false
        }
      }
      if (index === 2) {
        if (!(this.adxzsArr && this.adxzsArr.length > 0)) {
          return false
        }
      }
      this.isshowNormal = false
      this.keyIndex = index
      if (this.keyIndex === 0) {
        // 傲度动态点击数据
        this.getListData('portal-addt', 1, this.pageSize)
      } else if (this.keyIndex === 1) {
        // 傲度分享动态数据
        this.getListData('portal-adfx', 1, this.pageSize)
      } else if (this.keyIndex === 2) {
        // 傲度小知识动态数据
        this.getListData('protal-baike', 1, this.pageSize)
      }
    }
  },
  mounted () {
    // 请求傲度动态列表两条数据
    this.getSimpleData('portal-addt', 1, 2)
    // 请求傲度分享两条数据
    this.getSimpleData('portal-adfx', 1, 2)
    // 请求傲度小知识两条数据
    this.getSimpleData('protal-baike', 1, 2)
  }
};
</script>
<style lang="stylus">
.swiper-pagination-bullets{
  bottom 30px !important
}
.fd-home-content
  width 1200px
  margin 0 auto
  padding-top 81px
  padding-bottom 80px
  display flex
  justify-content space-between
  .fd-home-addt-wrap
    width 562px
    height 382px
    text-align left
    .fd-left-item
      height 400px
      background rgba(255,255,255,1)
      box-shadow 0px 0px 20px 0px rgba(229,229,229,1)
      padding-bottom 38px
      margin-top 27px
      .fd-home-left-item-title
        padding 15px 20px 14px 10px
        font-size 20px
        color #333
        text-overflow ellipsis
        white-space nowrap
        overflow hidden
      .fd-home-left-item-time
        font-size 14px
        padding-left 21px
        padding-bottom 18px
        color #B9B9B9
      .fd-home-left-item-text
        font-size 16px
        padding 0 21px
        height 48px
        line-height 24px
        color #646464
        text-indent 32px
        text-overflow -o-ellipsis-lastline
        overflow hidden
        text-overflow ellipsis
        display -webkit-box
        -webkit-line-clamp 2
        line-clamp 2
        -webkit-box-orient vertical
  .fd-home-adfx-wrap
    width 579px
    .fd-right-item
      padding-bottom 24px
      padding-left 23px
      padding-right 19px
      border-left 6px solid rgba(2,107,107,0.3)
      margin-top 27px
      background rgba(255,255,255,1)
      box-shadow 0px 0px 20px 0px rgba(229,229,229,0.46)
      .fd-right-top-wrap
        height 72px
        padding-top 20px
        padding-bottom 29px
        display flex
        justify-content space-between
        align-items center
        .fd-right-item-title
          font-size 20px
          line-height 24px
          color rgba(51,51,51,1)
          font-family 'PingFangSC-Regular'
          font-weight 400
        .fd-right-item-time
          font-size 14px
          color rgba(85,85,76,1)
          opacity 0.35
      .fd-right-item-text
        height 72px
        line-height 24px
        font-size 16px
        color #666666
        text-indent 32px
        overflow hidden
        display -webkit-box
        -webkit-line-clamp 3
        text-overflow ellipsis
        line-clamp 3
        -webkit-box-orient vertical
  .fd-title-wrap
    display flex
    justify-content space-between
    height 24px
    line-height 24px
    justify-content space-between
    .span1
      color #026BA7
      font-size 26px
    .span2
      color #044483
      font-size 14px
      cursor pointer
.fd-xiangxi-left-wrap
  width 175px
  .fd-xiangxi-left-item1
    width 175px
    height 50px
    color #026BA7
    font-size 20px
    line-height 50px
    text-align center
    cursor pointer
    background-color rgba(246,246,246,1)
    margin-bottom 10px
  .fd-xiangxi-left-item2
    height calc(100% - 180px)
    background-color rgba(246,246,246,1)
.fd-xiangxi-right-wrap
  width 987px
  // height 575px
  .fd-detail-title-text
    color #36393B
    font-size 20px
    font-weight 400
    text-align center
    margin-bottom 16px
  .fd-detail-time-text
    font-size 14px
    font-family 'PingFangSC-Regular'
    color rgba(153,153,153,1)
    margin-bottom 38px
  .fd-list-wrap
    height 533px
    .fd-list-item
      height 44px
      line-height 44px
      border-bottom 1px dashed #000000
      display flex
      justify-content space-between
      align-items center
      .fd-list-item-title
        color #666666
        font-size 18px
        text-align left
        cursor pointer
      .fd-list-item-time
        color #666
        font-size 18px
.fd-checked
  background-color rgba(2,107,167,1) !important
  color white !important
.fd-pagination
  display flex
  justify-content center
  align-items center
  margin-top 10px
.fd-button-style
  display: inline-block;
  vertical-align: middle;
  margin 0 5px;
  min-width: 50px;
  height: 32px;
  line-height: 30px;
  text-align: center;
  list-style: none;
  background-color: #fff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  font-family: Arial;
  font-weight: 500;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  transition: border .2s ease-in-out,color .2s ease-in-out
.fd-xiangxi-left-item1:hover
  color #76c3ff !important
  text-decoration underline
.fd-list-item-title:hover
  color #026BA7 !important
  text-decoration underline
.fd-adxzs-wrap
  margin-top 51px
</style>

