<template>
  <div id="app">
    <div id="nav">
      <div class="fd-nav-wrap">
        <!-- <div class="fd-left-wrap">
        </div> -->
        <div class="fd-left-wrap">
          <img src="./assets/images/logo.png" alt=""/>
        </div>
        <div class="fd-right-wrap">
          <router-link :is-click="isclick" class="fd-nav-item" @click.native="initData" :class="{'fd-border-bottom': key === '/home'}" to="/home">首页</router-link>
          <router-link class="fd-nav-item" :class="{'fd-border-bottom': key === '/about'}" to="/about">关于傲度</router-link>
          <router-link class="fd-nav-item" :class="{'fd-border-bottom': key === '/production'}" to="/production">产品系列</router-link>
          <router-link class="fd-nav-item" :class="{'fd-border-bottom': key === '/toujiao'}" to="/toujiao">投教通</router-link>
          <router-link class="fd-nav-item" :class="{'fd-border-bottom': key === '/zhaopin'}" to="zhaopin">诚聘英才</router-link>
          <router-link class="fd-nav-item" :class="{'fd-border-bottom': key === '/parner'}" to="/parner">合作伙伴</router-link>
        </div>
      </div>
    </div>
    <router-view class="fd-zj-parter" v-if="isRouterAlive"/>
    <!-- <pre style="text-align:center">
     **************************************************************
     *                                                            *
     *    .=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-.      *
     *    |                     ______                     |      *
     *    |                  .-"      "-.                  |      *
     *    |                 /            \                 |      *
     *    |     _          |              |          _     |      *
     *    |    ( \         |,  .-.  .-.  ,|         / )    |      *
     *    |     > "=._     | )(__/  \__)( |     _.=" <     |      *
     *    |    (_/"=._"=._ |/     /\     \| _.="_.="\_)    |      *
     *    |           "=._"(_     ^^     _)"_.="           |      *
     *    |               "=\__|IIIIII|__/="               |      *
     *    |              _.="| \IIIIII/ |"=._              |      *
     *    |    _     _.="_.="\          /"=._"=._     _    |      *
     *    |   ( \_.="_.="     `--------`     "=._"=._/ )   |      *
     *    |    > _.="                            "=._ <    |      *
     *    |   (_/                                    \_)   |      *
     *    |                                                |      *
     *    '-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-='      *
     *                                                            *
     *           LASCIATE OGNI SPERANZA, VOI CH'ENTRATE           *
     **************************************************************
     </pre> -->
    <div class="fd-footer-div">
      <div>
        <p>版权所有：上海傲度金融信息服务有限公司  &nbsp;&nbsp;&nbsp;<a href="http://www.beian.miit.gov.cn" target="_blank">沪ICP备16039688号-1</a></p>
        <p>与本网站所载资料有关的所有版权、专利等知识产权及其他任何权利均为傲度信息所有，未经许可不得使用，不得转载、摘编。</p>
        <p style="margin-top:10px"><img @click="jumpToWg" style="margin-right:5px;cursor:pointer" src="./assets/images/ga.png"/><span style="cursor:pointer" @click="jumpToWg">沪公网安备&nbsp; 31011502008938号</span></p>  
      </div>
                   
    </div>
  </div>
</template>
<script>
export default {
  name: 'app',
  data () {
    return {
      key: '/home',
      isclick: false,
      isRouterAlive:true
    }
  },
  watch: {
    '$route.path': {
      handler (val, oldVal) {
        this.key = val
      },
      deep: true
    }
  },
  methods: {
    // 网关跳转
    jumpToWg () {
      window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502008938')
    },
    // 首页点击事件
    initData () {
      this.isRouterAlive = false
      this.$nextTick(() => (this.isRouterAlive = true))
    }
  },
  mounted () {
    this.key = this.$route.path
  }
};
</script>
<style lang="stylus">
*
  margin 0
  padding 0
  outline 0
  box-sizing border-box
img
  vertical-align middle
.fd-zj-parter
  min-height calc(100vh - 288px)
.fd-border-bottom
  border-bottom 1px solid rgba(26,39,78,1) !important
  color #1A274E !important
  font-weight 600
#app
  // font-family 'Avenir', Helvetica, Arial, sans-serif
  // -webkit-font-smoothing antialiased
  // -moz-osx-font-smoothing grayscale
  // text-align center
  // color #2c3e50
  min-width 1200px
  width 100%

.fd-footer-div
  text-align center
  width 100%
  height 188px
  background-color rgba(0,37,58,1)
  display flex
  justify-content center
  align-items center
  flex-wrap wrap
  color #FFFFFF
  font-family 'STHeitiSC-Light'
  font-weight 300
  font-size 14px
#nav
  height 100px
  width 100%
  background white
  .fd-nav-wrap
    width 1200px
    height 100%
    margin auto
    display flex
    justify-content space-between
    align-items center
    .fd-left-wrap
      width 252px
      height 73px
    .fd-right-wrap
      width 700px
      display flex
      justify-content space-between
      align-items center
      .fd-nav-item
        max-width 80px
        height 60px
        line-height 60px
        color #1A274E
        font-size 18px
        letter-spacing 0
        text-decoration none
        font-family 'SourceHanSansCN-Regular'
.fd-nav-item:hover
  border-bottom 1px solid rgba(26,39,78,1) !important
  color #1A274E !important
  font-weight 600
</style>
