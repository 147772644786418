/* eslint-disable */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
// import './plugins/element.js'
// import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/reset.css'
// import 'element-ui/lib/theme-chalk/index.css'
import iView from 'iview';
import 'iview/dist/styles/iview.css';
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'wzg7UoUT3zG1ZZacXeOg6D6W4L1a8SO2'  //这个地方是官方提供的ak密钥
 })
Vue.use(VueAwesomeSwiper);
// Vue.use(ElementUI)
Vue.use(iView);
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
