/* eslint-disable */
import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: '/home'
    },
    {
      path: '/home',
      name: "home",
      component: Home
    },
    {
      path: "/about",
      name: "about",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/About.vue")
    },
    {
      path: '/production',
      name: 'cpxl',
      component: () =>
        import('./views/Cpxl.vue')
    },
    {
      path: '/toujiao',
      name: 'tjt',
      component: () =>
        import('./views/Tjt.vue')
    },
    {
      path: '/zhaopin',
      name: 'cpyc',
      component: () =>
        import('./views/Cpyc.vue')
    },
    {
      path: '/parner',
      name: 'hzhb',
      component: () =>
        import('./views/Hzhb.vue')
    }
  ]
});
